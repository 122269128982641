.count-survey-header {
    margin: 10px;
}

.count-survey-title {
    font-size: 30px;
    margin: 0px;
    padding: 0px;
}

.count-survey-p {
    padding: 0px;
    margin: 0px;
}

.count-survey-instructions {
    margin: 0px;
    padding: 0px;
}

.count-survey-form {
    margin: 10px;
}

.ranking-input-box {
    width: 85px;
    border-color: rgb(108, 208, 255);
}

.short-answer-input-box {
    border-color: rgb(59, 214, 28);
}

.count-survey-btns {
    margin-bottom: 50px;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
    .count-survey-page-wrapper {
        margin-left: 250px;
        margin-right: 250px;
    }
}


@media only screen and (min-width: 1300px) {
    .count-survey-page-wrapper {
        margin-left: 350px;
        margin-right: 350px;
    }
}


@media only screen and (min-width: 1500px) {
    .count-survey-page-wrapper {
        margin-left: 500px;
        margin-right: 500px;
    }
}
