.share-name-text {
    margin: 10px;
    padding: 0px;
}

.analysis-header {
    margin: 10px;
}

.analysis-title {
    font-size: 30px;
    margin: 0px;
}

.analysis-entry-count {
    margin: 0px;
    padding: 0px;
}

.analysis-header-btns-container {
    margin-top: 10px;
    display: flex;
}

.analysis-header-download-btn-wrapper {
    margin-right: 10px;
}

.analysis-chart-container {
    margin: 10px;
}

.analysis-bar-graph-wrapper {
    margin-bottom: 20px;
    background-color: white;
}

.analysis-bar-graph-title-wrapper {
    display: flex;
}

.analysis-bar-graph-title {
    font-size: 20px;
}

.analysis-bar-graph-stats {
    padding: 0px;
    margin: 0px;
    margin-left: 10px;
}

.info-circle {
    border-radius: 50%;
    width: 22px;
    height: 22px;
    border-style: solid;
    border-color: rgb(199, 199, 199);
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-width: 2px;
}

.info-circle:hover {
    box-shadow: 0px 0px 3px rgb(122, 122, 122);
}

.info-btn-screen {
    border-radius: 50%;
}

.info-i {
    color: rgb(199, 199, 199);
    font-weight: bold;
    font-family: monospace;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}

@media only screen and (min-width: 576px) {
}

@media only screen and (min-width: 767px) {
    .analysis-header {
        margin: 30px;
    }

    .analysis-chart-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        margin-bottom: 40px;
    }

    .analysis-bar-graph-wrapper {
        width: 40vw;
    }

    .analysis-header {
        display: flex;
        align-items: center;
    }

    .analysis-header-btns-container {
        margin-left: 20px;
        margin-top: 0px;
    }
}

@media only screen and (min-width: 1100px) {
    .analysis-bar-graph-wrapper {
        width: 30vw;
    }
}

@media only screen and (min-width: 1700) {

    .analysis-chart-container {
        width: 80vw;
    }

    .analysis-page-wrapper {
        padding-left: 400px;
        padding-right: 400px;
    }

    .analysis-bar-graph-wrapper {
        width: 85vw;
    }
}
