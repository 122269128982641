.create-survey-title {
    font-size: 30px;
    margin: 10px;
}

.create-survey-form {
    margin: 10px;
    margin-bottom: 50px;
}

.question-input-header {
    display: flex;
    align-items: center;
}

.question-input-header-text {
    margin: 0px;
    padding: 0px;
    margin-right: 10px;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
    .create-survey-page-wrapper {
        margin-left: 250px;
        margin-right: 250px;
    }
}


@media only screen and (min-width: 1300px) {
    .create-survey-page-wrapper {
        margin-left: 350px;
        margin-right: 350px;
    }
}


@media only screen and (min-width: 1500px) {
    .create-survey-page-wrapper {
        margin-left: 500px;
        margin-right: 500px;
    }
}
