.footer-container {
    /* border-top-style: solid; */
    border-width: 2px;
    box-shadow: 0px 0px 10px rgb(189, 189, 189);
    border-color: rgb(221, 221, 221);
    min-height: 40px;
    display: none;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(255, 255, 255);
    position: fixed;
    bottom: 0;
    z-index: 1000;
}

.footer-text {
    margin: 0px;
    padding: 0px;
    color: gray;
    font-size: 12px;
}

.footer-text-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-ig-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.bi-instagram {
    color: gray;
    margin-right: 5px;
}

.footer-email-link {
    color: gray;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
    .footer-large-only {
        display: none;
    }

    .footer-container {
        justify-content: center;
    }
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 768px) {
}


@media only screen and (min-width: 992px) {
    .footer-container {
        display: flex;
    }
}


@media only screen and (min-width: 1200px) {
}
