.homepage-window-container {
    margin-bottom: 50px;
}

.homepage-title {
    padding: 0px;
    margin: 10px;
    font-size: 40px;
}

.survey-container {
    margin: 10px;
}

.survey-item-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(233, 233, 233);
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.homepage-survey-buttons {
    display: flex;
}

.homepage-survey-name {
    margin: 0px;
    padding: 0px;
    margin-right: 5px;
}

.homepage-survey-btn-wrapper {
    margin-left: 5px;
    margin-right: 5px;
}

.create-survey-btn-wrapper {
    margin: 10px;
}

.homepage-help-btn-wrapper {
    display: none;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
    .homepage-window-container {
        margin-left: 130px;
        margin-right: 130px;
    }
}


@media only screen and (min-width: 1000px) {
    .homepage-window-container {
        margin-left: 200px;
        margin-right: 200px;
        margin-top: 30px;
    }

    .homepage-help-btn-wrapper {
        display: block;
    }
}


@media only screen and (min-width: 1500px) {
    .homepage-window-container {
        margin-left: 500px;
        margin-right: 500px;
    }
}
