.navbar-welcome-text {
    color: red;
}

#users-nav-link, #achievements-nav-link {
    display: none;
}

.navbar-welcome-text {
    margin-left: 10px;
}

.desktop-navbar {
    display: none;
}

/* Screen size specs */
@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 768px) {
}


@media only screen and (min-width: 992px) {
    #users-nav-link, #achievements-nav-link {
        display: block;
    }    
    
    .mobile-navbar {
        display: none;
    }

    .desktop-navbar {
        display: block;
    }
}


@media only screen and (min-width: 1200px) {
}
