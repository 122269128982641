.biking-img {
    width: 1000px;
    height: 400px;
    object-fit: cover;
    object-position: 50% 15%;
}

.help-carousel-container {
    background-color: rgb(49, 49, 54);
    height: 100%;
    padding-top: 30px;
}

.help-gif-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 170px;
}

.help-gif {
    border-style: solid;
}

.carousel-caption {
    background-color: rgba(255, 0, 0, 0);
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
