.sign-in-page {
    height: 100vh;
    width: 100vw;
    position: relative;
    /* background-color: black; */
}

.sign-in-background-img {
    object-fit: cover;
    height: 100vh;
    width: 100vw;
    opacity: 0.7;
}

.sign-in-card {
    height: 100vh;
    width: 100vw;
    position: absolute;
    margin: auto;
    top: 0; left: 0; bottom: 0; right: 0;
    background-color: white;
}

.sign-in-card-wrapper {
    margin: 20px;
    margin-top: 40px;
    position: relative;
}

.sign-in-header {
    text-align: center;
    margin-bottom: 40px;
}

.input-group, .sign-in-button-wrapper, .sign-in-links {
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 30px;
}

.btn {
    width: 100%;
}

.login-errors {
    margin-top: 10px;
    color: red;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 768px) {
    .input-group, .sign-in-button-wrapper, .sign-in-links{
        padding-left: 40px;
        padding-right: 40px;
    }

    .sign-in-card-wrapper {
        margin-top: 25vh;
    }
}


@media only screen and (min-width: 992px) {
    .sign-in-card-wrapper {
        margin-top: 40px;
    }
    .sign-in-card {
        height: 450px;
        width: 600px;
        box-shadow: 0px 0px 50px rgb(139, 139, 139);
        border-radius: 20px;
    }
}


@media only screen and (min-width: 1200px) {
}
